.general-modal-container {
  .modal-content {
    padding: 15px !important;
    .widget {
      padding-right: 0;
      .create-bank-account-form {
        padding-right: 0;
      }
    }
  }
}

.modal-form-create-bank-account {
  .column {
    &.full {
      padding-bottom: 0;
      .status-label {
        .column {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }
}

.modal-form-edit-bank-account {
  .column {
    &.full {
      padding-bottom: 0;
      .status-label {
        .column {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }
}

.create-bank-account-form {
  padding-bottom: 0;
  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.tfsa-warning {
  font-size: 14px;
  margin-bottom: 25px;
  .inner-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    font-size: 14px;
    p {
      margin-bottom: 0;
      padding-bottom: 0;
      padding-top: 10px;
      font-size: 14px;
    }
  }
  .fa-triangle-exclamation {
    margin-left: auto;
  }
  .column {
    padding-bottom: 0 !important;
    p {
      margin-bottom: 0;
      font-size: 14px;
    }
  }
}
