$dark-mode-text: #b0e1b8;
$dark-mode-background: #1d1d1d;
$dark-mode-card-background: #2f2f2f;
$dark-mode-border: #4b7552;

$text-color: #414141;
$label-color: #6c7c93;
$primary: #6f4f9e;
$primary-header: #463e93;
$secondary-header: #9366a5;
$nav-bar-background: white;
$nav-items-background: white;
$main-background-color: white;
$grey: #d0d0d0;
$light-grey: #eeeeee;
$black: #383838;
$red: #e45c5c;
$bright_red: #c90000;
$table-row: #eae5f1;
$danger: #e45d5c;
$info: #6f4f9e;
$highlight: #fbac33;
$investment-progress: #21b092;
$green-button: #29b311;
$footer-grey: #f2f2f2;

// New Additions for the investment page !
$grade-highlight: #937bb6;
$neutral: #f2f2f2;
$gradient: linear-gradient(to right, #9366a5, #463e93);
$background-row: rgb(242, 242, 242);

// Media Queries !
$xl: 1408px;
$lg: 1024px;
$md: 960px;
$sm: 769px;
$xs: 576px;
