// @import "~font-awesome/css/font-awesome.css";
@font-face {
  font-family: "Font Awesome 6 Pro";
  src: url(./fonts/fontawesome-6/fa-solid-900.woff2),
    url(./fonts/fontawesome-6/fa-solid-900.ttf);
}
@font-face {
  font-family: "GilroyBold";
  src: url(./fonts/gilroy/Gilroy-ExtraBold.woff2),
    url(./fonts/gilroy/Gilroy-ExtraBold.woff);
}

@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Medium.woff2),
    url(./fonts/gilroy/Gilroy-Medium.woff);
}

@import "./fonts/fontawesome-6/fontawesome.css";
@import "./fonts/proxima-nova.font.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}
