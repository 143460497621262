@import "variables";
.dashboard-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 122px;
  background-color: white;
  .dashboard-logo {
    .app-logo {
      width: 319px !important;
    }
  }
}

#nav-bar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .nav-bar {
    position: initial !important;
    background-color: initial !important;
    .nav-bar-contents {
      max-width: initial !important;
    }
  }
}

#breadcrumbs {
  span {
    a {
      font-size: 14px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 14px;
    }
  }
}

.dashboard-content {
  .main-header {
    padding-top: 0 !important;
    height: 6rem !important;
    width: 100%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    display: flex;
    justify-content: flex-start;
    .inner-wrapper {
      max-width: 55rem;
    }
    h1 {
      margin-left: 6rem;
      color: white;
      font-size: 18px;
    }
  }
}

#notifications {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 99999;
}
#notifications .notification {
  width: 280px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  opacity: 0;
}
#notifications .notification .notification-inner {
  margin: 15px 15px 0px 0px !important;
  padding: 22px;
  background-image: linear-gradient(to top, #e2e4e7 0%, #ffffff 100%);
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  color: #354052;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.35);
  position: relative;
  word-wrap: break-word;
  border-radius: 4px;
}
#notifications .notification .notification-inner a {
  color: #2f6de1;
  text-decoration: underline;
}
#notifications .notification .notification-inner.success {
  border-bottom: 4px solid #27aa11;
}
#notifications .notification .notification-inner.fail {
  border-bottom: 4px solid #6f4f9e;
  animation: shake 0.7s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}
#notifications .notification .notification-inner.error {
  border-bottom: 4px solid #c90000;
  animation: shake 0.7s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}
#notifications .notification .notification-inner .close {
  display: block;
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 10px;
}
#notifications .notification .notification-inner ul {
  list-style-type: disc;
  list-style-position: outside;
  margin: 1em 0 0 0.75em;
}
#notifications .notification .notification-inner ul li {
  margin: 0.5em 0;
  line-height: 1.25em;
}

.black-text {
  font-weight: 400 !important;
  color: black !important;
}

.investment-table {
  .search-box-container {
    display: none;
  }
  .faux-table {
    .table-header {
      background-color: $table-row !important;
    }
    .table-total {
      background-color: $table-row !important;
      td {
        font-weight: bold !important;
      }
    }
    .faux-cell {
      &.action_buttons {
        background-color: white !important;
      }
      &.white-row {
        background-color: white !important;
      }
    }
  }
}

.overview-table {
  .search-box-container {
    display: none;
  }
  .faux-table {
    tbody {
      .faux-row {
        .faux-cell {
          color: black !important;
        }

        background-color: $table-row !important;
      }
    }
  }
}
