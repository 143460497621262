@import "variables";
@import "@truenorthmortgage/olympus/src/sass/style";
@import "login";
@import "dashboard";

html,
body,
#root,
#main {
  height: 100%;
}

#root.modal-open {
  overflow: hidden;
}

.columns {
  margin: 0;
}

body {
  font-family: Gilroy, sans-serif;
  color: $text-color;
  // @media (prefers-color-scheme: dark) {
  //   color: $dark-mode-text;
  // }
}

a {
  text-decoration: none;
  color: $primary;
  cursor: pointer;
}

p {
  font-size: 0.8rem;
  color: $text-color;
  // @media (prefers-color-scheme: dark) {
  //   color: $dark-mode-text;
  // }
}

hr {
  margin: 1.5rem 0;
}

label {
  font-size: 0.8rem;
  color: $label-color;
  font-weight: bold;

  // @media (prefers-color-scheme: dark) {
  //   color: $dark-mode-text;
  // }
}

h1 {
  font-family: GilroyBold;
  font-size: 2.75rem;
  color: $primary;
  margin: 0;
}

h2 {
  font-size: 1.5rem;
  color: #000;
  font-weight: bold;
  // @media (prefers-color-scheme: dark) {
  //   color: $dark-mode-text;
  // }
}

h3 {
  font-size: 1.125rem;
  font-weight: bold;
  // @media (prefers-color-scheme: dark) {
  //   color: $dark-mode-text;
  // }
}

h4 {
  font-size: 0.8rem;
  font-weight: 800;
  margin: 1rem 0;
}

.primary {
  color: $primary;
}

.negative-number {
  color: $red !important;
  margin-top: 0 !important;
}

.buttons {
  margin-top: 1rem;
}

input:not([type="checkbox"]),
button,
.button {
  -webkit-appearance: none;
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.8rem;
}

input {
  border: 1px solid $grey;
  height: 2.1rem;
}

button,
.button {
  margin: 0;
  text-align: center;
  &.primary,
  &.primary:hover {
    background: $primary !important;
    border-color: $primary;
    color: white;
  }
}

i {
  font-size: 1rem;
  &.error {
    color: $red;
  }
}

.divider {
  height: 3px;
  width: 100%;
  background-color: $primary;
  // @media (prefers-color-scheme: dark) {
  //   background-color: $dark-mode-border;
  // }
}

.divider-grey {
  height: 1px;
  width: 100%;
  background-color: $light-grey;
  // @media (prefers-color-scheme: dark) {
  //   background-color: #000;
  // }
}

.loading {
  width: 100%;
  text-align: center;
  img {
    width: 8rem;
  }
}

.loading-full {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000066;
  z-index: 10;
  img {
    width: 12rem;
  }
}

#main {
  background: linear-gradient(
    274deg,
    $primary-header 0%,
    $secondary-header 100%
  );
  display: block;
  margin: 0;

  .main-header {
    height: 7rem;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 5rem;
    padding-left: 1rem;

    @media (max-width: 375px) {
      height: 38px !important;
      padding-top: initial !important;
      min-height: initial !important;
    }

    #breadcrumbs {
      width: 100%;
      max-width: 81rem;
      margin: auto;
      z-index: 1;

      i {
        font-size: 0.6rem;
        color: white;
        // @media (prefers-color-scheme: dark) {
        //   color: $dark-mode-text;
        // }
        margin: 0 0.5rem;
        opacity: 0.4;
      }
      a {
        color: white;
        // @media (prefers-color-scheme: dark) {
        //   color: $dark-mode-text;
        // }
        font-size: 0.8rem;
        font-weight: bold;
      }
      span:not(:last-of-type) a {
        text-decoration: underline;
      }
    }

    img {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .main-content {
    background-color: $main-background-color;
    min-height: calc(100vh - 180px);
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 2rem 1rem;
    display: flex;
    justify-content: center;
    position: relative;
    @media (max-width: 375px) {
      min-height: calc(100vh - 8.6rem) !important;
    }
    @media (max-width: 414px) {
      min-height: calc(100vh - 8.6rem) !important;
    }
    &.login {
      .greeting {
        margin-top: 60px;
      }
    }
  }
}

#nav-bar {
  .nav-bar {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: 5rem;
    width: 100%;
    background-color: $nav-bar-background;
    // @media (prefers-color-scheme: dark) {
    //   background-color: $dark-mode-background;
    // }

    .nav-bar-contents {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      max-width: 55rem;
      margin: auto;
    }
  }
  #nav-items {
    position: fixed;
    font-family: GilroyBold;
    font-size: 1.5rem;
    z-index: 7;

    right: -20rem;
    top: 0;
    height: 100vh;
    width: 20rem;
    background-color: $nav-items-background;
    // @media (prefers-color-scheme: dark) {
    //   background-color: $dark-mode-background;
    // }
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease-in-out;

    .close-button {
      color: $primary;
      position: absolute;
      top: 0;
      right: 0;
      padding: 1rem;
    }

    ul {
      list-style-type: none;
      padding: 3rem 0;
      li {
        padding: 0.75rem;
        text-align: center;
        font-weight: 800;

        .link-button {
          font-weight: 800;
          font-size: 100%;
          font-family: GilroyBold;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  i {
    cursor: pointer;
  }

  #nav-items.open {
    right: 0;
  }

  #nav-items.open + .nav-mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    width: 100vw;
    height: 100vh;
    pointer-events: all;
    background-color: rgba(0, 0, 0, 0.35);
  }

  .app-logo {
    width: 319px !important;
  }
}

.account-info-card,
.login-card,
.setup-card,
.not-found-card {
  h1 {
    margin-top: 2rem;
  }

  h1 + p,
  h1 + div {
    margin: 1rem 0 0 0;
  }
  .img-container {
    width: 100%;
    text-align: center;
  }

  button,
  input:not([type="checkbox"]) {
    width: 100%;
  }

  .checkbox {
    input:checked ~ .checkmark {
      border: 1px solid $primary;
      background-image: none;
      background-color: $primary;
    }
  }

  .columns {
    margin: 0;
  }

  .column {
    padding: 0;
  }

  .button,
  input,
  .checkbox {
    margin-top: 1rem;
  }
}

.login-card {
  &.four-zero-four-not-found {
    .greeting {
      text-align: center;
    }
  }
  &__link {
    text-align: center;
    margin-top: 1.5rem;
  }
}

.account-info-card {
  max-width: 900px;
  padding-left: 1rem;
  padding-right: 1rem;

  h1 {
    text-align: center;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
}

.setup-card {
  max-width: 24rem;
}

.not-found-card {
  p {
    font-size: 16px;
    margin: 1rem 0 0 0;
  }

  p:last-child {
    margin-bottom: 1rem;
  }
}

.dashboard,
.heloc,
.account {
  width: 100%;
  height: 100%;
  max-width: 81rem;
  margin-bottom: 5rem;
}

.dashboard {
  h1 {
    margin-bottom: 2rem;
  }
}

.footer {
  margin: 0 auto;
  width: 100%;
  max-width: 50rem;
  height: 58px;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  color: $primary;
  font-size: 14px;

  @media only screen and (max-width: 414px) {
    flex-direction: column;
    height: 100px;
    text-align: center;
    justify-content: initial;
    .footer__phone-link {
      margin-top: 12px;
      display: initial;
      text-align: center;
    }
    .footer__no-account {
      display: initial;
      text-align: center;
    }
  }

  &__phone-link {
    display: inline-flex;
    & a {
      font-weight: bold;
      margin-left: 0.25rem;
    }
  }
}

.has-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.footer-content {
  margin-top: 4rem;
  width: 100%;
  height: 4rem;
}

.loan-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  padding: 1rem;
  h3,
  p {
    margin: 0;
    color: $black;
    // @media (prefers-color-scheme: dark) {
    //   color: $dark-mode-text;
    // }
  }
  h3 {
    margin-top: 0.5rem;
  }
}

.table {
  margin-bottom: 2rem;
  td {
    padding: 1rem !important;
    color: #414141;
    // @media (prefers-color-scheme: dark) {
    //   color: $dark-mode-text !important;
    //   border-bottom-color: #000000 !important;
    // }
  }

  &:not(.transactions) td:first-child {
    font-weight: bold !important;
  }

  &.primary {
    tr {
      background-color: #f5ffee;
      // @media (prefers-color-scheme: dark) {
      //   background-color: $dark-mode-card-background !important;
      // }
      td {
        &:first-child {
          color: $primary;
        }
      }
    }
  }
}

.loan-header {
  margin: 1rem 0 2rem 0;

  h1,
  h3,
  p {
    margin: 0;
  }
  h1 {
    margin: 0.25rem 0;
  }
}
.link-cards {
  width: 100%;
  overflow-x: scroll;
  padding: 1rem 1rem 2rem 1rem;
  display: flex;

  .link-card + .link-card {
    margin-left: 1rem;
  }
}
.link-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 5.5rem;
  width: 8rem;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.16);
  i {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
  span {
    font-size: 0.7rem;
    text-align: center;
  }
}
.cards {
  padding-bottom: 1rem;

  .card {
    // @media (prefers-color-scheme: dark) {
    //   background-color: $dark-mode-card-background;
    // }
    i,
    span {
      color: $primary;
    }
    span {
      height: 50%;
      display: flex;
      align-items: center;
      // @media (prefers-color-scheme: dark) {
      //   color: $dark-mode-text;
      // }
    }
  }
}
.tab-group {
  .tabs {
    margin: 1rem 0;
    // @media (prefers-color-scheme: dark) {
    //   background-color: $dark-mode-card-background;
    // }
  }

  h2 {
    margin-top: 2rem;
  }
}

.modal {
  &.open {
    .modal-box {
      bottom: 25%;
    }
  }
  .modal-box {
    font-size: 0.9rem;
    line-height: 1.3rem;
    background-color: white;
    position: fixed;
    z-index: 99998;
    bottom: -100%; /* Start offscreen */
    left: 0;
    right: 0;
    margin: auto;
    max-width: 40rem;
    transition: bottom 0.5s ease-in-out; /* Add transition for bottom */
    max-height: calc(100vh - 10%);

    .modal-header {
      color: $primary;
      font-family: GilroyBold;
      font-size: 1.5rem;
      border: none;
      box-shadow: none;
      padding: 1.5rem 2rem;
      position: relative;
      text-align: center;

      .modal-close {
        width: 1rem;
        height: 2rem;
        top: 0.5rem;
        display: flex;
        align-items: center;

        i {
          font-size: 1.5rem;
        }

        @media only screen and (max-width: 414px) {
          position: initial !important;
        }
      }

      @media (max-width: 768px) {
        width: 100%;

        .modal-close {
          position: absolute;
          right: 1rem;
        }
      }
    }

    @media (max-width: 768px) {
      .modal-content {
        padding: 1rem;
      }
      justify-content: flex-start;
    }

    .modal-content {
      width: 100%;
    }

    p.padded {
      font-size: 0.8rem;
      padding-left: 20px;
    }
  }
}

.card .modal span {
  text-align: left !important;
  height: unset;
}

.modal-header {
  color: #3c984b;
  font-family: GilroyBold;
  font-size: 1.5rem;
  border: none;
  box-shadow: none;
  padding: 1.5rem 2rem;
  position: relative;
  text-align: center;
  .modal-close {
    position: absolute;
    right: 1rem;
    width: 1rem;
    height: 2rem;
    top: 0.5rem;
    display: flex;
    align-items: center;
    i {
      font-size: 1.5rem;
      cursor: pointer;
    }
    @media only screen and (max-width: 414px) {
      position: initial !important;
    }
  }
}

.modal-mask {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: -webkit-wrap;
  cursor: default;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0px;
  left: 0px;
  overflow-y: auto;
  display: inline-flex;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 99998;
  pointer-events: none;
}

.modal-container.open .modal-mask {
  opacity: 1;
  pointer-events: all;
}

.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
.flatpickr-weekday,
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: $primary !important;
}

form {
  input {
    margin-bottom: 1rem;
  }
}

.form {
  .column {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 400px) {
  .tabs {
    overflow-x: scroll;
    justify-content: flex-start !important;
  }
}

//.notification {
//  position: fixed;
//  top: 10%;
//  left: 0;
//  right: 0;
//  margin: auto;
//  width: 100% !important;
//  max-width: 55rem !important;
//  z-index: 10000;
//
//  .notification-background {
//    position: absolute;
//    width: 100%;
//    height: 100%;
//  }
//
//  .notification-inner {
//    display: flex;
//    background-image: none !important;
//
//    align-items: center;
//    justify-content: center;
//    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2) !important;
//    border-radius: 0 !important;
//    background-color: white;
//    margin: 15px 7px 0 7px !important;
//
//    @media (max-width: 55rem) {
//      margin: 1rem 0 !important;
//    }
//    i {
//      font-size: 1.5rem;
//      margin-right: 1rem;
//    }
//    span {
//      font-size: 1rem;
//    }
//  }
//
//  .notification-inner.success {
//    i,
//    span {
//      color: #3c984b;
//    }
//    .notification-background {
//      background-color: rgba(60, 152, 75, 0.1);
//    }
//    border: 3px solid #3c984b !important;
//  }
//  .notification-inner.error {
//    i,
//    span {
//      color: #e45c5c;
//    }
//    .notification-background {
//      background-color: rgba(228, 92, 92, 0.1);
//    }
//    border: 3px solid #e45c5c !important;
//  }
//}

.errors p {
  margin-bottom: 0;
  color: #e45c5c;
}

.right {
  text-align: right;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: $primary;
  cursor: pointer;
}

.external-links {
  a {
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.6px;
  }
}

.login-card {
  .app-logo {
    width: 319px !important;
  }
  .inner-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      text-align: center;
    }
    h3 {
      text-align: center;
    }
  }
}

.black-bold {
  font-weight: bold !important;
  color: black !important;
}

.column {
  padding-left: 0;
  padding-right: 0;
  &.transaction {
    gap: 15px;
    display: flex;
    margin-top: 10px;
    padding-left: 0;
    padding-right: 0;
    .column {
      &.date-select {
        margin-top: 20px;
      }
      &.button {
        margin-top: 20px;
        flex-basis: 10%;
        padding-top: 0;
        padding-bottom: 0;
        height: 33px;
        .button {
          display: flex;
          height: 33px;
          padding: 10px 15px;
          align-items: center;
        }
      }
    }
    &.date {
      display: flex;
      flex-direction: column;
      label {
        margin-right: auto !important;
      }
    }
    &.right {
      margin-top: 10px !important;
      .column {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.search-wrap {
  display: flex;
  flex-basis: 100%;
  .search-component {
    flex-basis: 50%;
  }
  margin-bottom: 10px;
  .column {
    &.search-select {
      flex-basis: max-content;
      margin-left: auto;
    }
  }
}

.progress-wrap {
  .progress-bar-max {
    position: relative;
    top: 10px;
    color: #c90000;
    div {
      width: 20px;
      border-left: solid 2px #c90000;
      span {
        top: 11px;
        position: relative;
        left: -11px;
        font-size: 10px;
      }
    }
  }
  .progress-bar {
    height: 5px;
    background-color: #eeeeee;
    border-radius: 1px;
    display: flex;
    .progress-bar-section {
      flex: 1;
    }
    .section1 {
      background-color: #3c984b;
    }
    .section2 {
      background-color: #f9ad40;
    }
    .section3 {
      background-color: #eee;
    }
  }
  .progress-bar-text {
    margin: 15px 0px 10px 1px;
    color: #354052;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    .color_section1 {
      color: #3c984b;
      font-weight: bold;
    }
    .color_section2 {
      color: #f9ad40;
      font-weight: bold;
    }
    .color_section3 {
      color: #eee;
      font-weight: bold;
    }
  }
}

.form-password {
  &.reset-page {
    @media (max-width: 375px) {
      .greeting {
        margin-top: 30px !important;
      }
    }
  }
  .greeting {
    text-align: center;
  }
  .form-password-form {
    padding-left: 16%;
    padding-right: 16%;
  }
  .form-password-subheader {
    color: #000;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 141.176% */
    letter-spacing: 0.255px;
    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @media (max-width: 375px) {
      display: flex;
      flex-direction: column;
      span {
        flex-direction: column;
        display: flex;
      }
      .hide-mobile {
        display: none;
      }
    }
  }
}

.investment-details-table {
  margin-bottom: 0;
  .purchase-order-account-details {
    span {
      flex-basis: initial !important;
    }
  }
  .faux-cell {
    &.action_buttons {
      display: flex;
      .action-buttons {
        margin-left: auto !important;
      }
    }
  }
  .faux-row {
    background-color: white !important;
  }
}

.investment-details-cell {
  display: flex !important;
  td {
    margin-left: auto;
  }
}
.investment-bottom-row {
  background-color: $table-row !important;
  .faux-row {
    &.table-header {
      background-color: $table-row !important;
      .faux-cell {
        background-color: $table-row !important;
        #view-listing {
          .fa-file-lines {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .view-listing {
    margin-right: 16px;
    margin-left: auto;
  }
  .action_buttons {
    padding: 0 !important;
    background-color: $primary !important;
  }
}

.no-left-margin {
  margin-left: 0 !important;
}

.no-left-padding {
  padding-left: 0 !important;
}

.no-left-padding-purchase {
  padding-left: 0 !important;
  .column {
    padding-left: 0 !important;
  }
}
.no-right-padding {
  padding-right: 0 !important;
}

.purchase-input-columns {
  margin-top: 20px;
}

.content-container {
  .content-wrap {
    margin-top: 15px;
    display: flex;
    .left-side {
      flex-basis: 50%;

      h4 {
        margin-left: 15px;
        color: $primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .right-side {
      flex-basis: 50%;
      .inner-content-wrap {
        display: flex;
        flex-direction: column;
        padding-top: 12px;
      }
      .inner-wrap {
        .grade-a {
          margin-top: 10px;
          display: flex;
        }
      }
      h4 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.lower-content-container {
  margin-top: 14px;
  display: flex;
  .left-side {
    flex-basis: 50%;
    h4 {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .right-side {
    flex-basis: 50%;
    h4 {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.background-circle-fa {
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
  font-size: 28px;
  margin-left: 18px;
  span {
    position: absolute;
    color: white;
    font-size: 16px;
    padding-top: 6px;
  }
}

.grade {
  &.grade-a {
    .background-circle-fa {
      color: #21b092;
    }
  }
  &.grade-b {
    .background-circle-fa {
      color: #fbac33;
    }
  }
  &.grade-c {
    .background-circle-fa {
      color: $danger;
    }
  }
  &.grade-d {
    .background-circle-fa {
      color: #9d9d97;
    }
  }
  &.grade-i {
  }
  &.grade-other {
    .background-circle-fa {
      color: #6d6d64a8;
    }
  }
}

.inner-grade-wrap {
  color: white;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.bar-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  .vertical-bar {
    background-color: $info;
    width: 3px;
    height: 13%;
    position: absolute;

    .top-ball {
      background-color: $info;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      position: absolute;
      top: 0;
      left: -2px;
    }

    .bottom-ball {
      position: absolute;
      bottom: 0;
      left: -2px;
      background-color: $info;
      border-radius: 50%;
      width: 8px;
      height: 8px;
    }
  }
}

.score-wrap {
  margin-top: 12px;
  max-width: 92px;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 16px;
  height: 32px;
  background-color: $highlight;
  color: white;
  &.inline {
    margin-top: 0 !important;
    height: 20px;
    max-width: 54px;
    .fa-signal-bars-good {
      padding-top: 1px;
      font-size: 14px;
    }
    .credit-span {
      padding-top: 0;
      font-size: 12px;
    }
  }
  &.circle {
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }
  .credit-bars {
    padding-top: 8px;
    padding-right: 8px;
  }
  .credit-span {
    padding-top: 8px;
  }
}

.progress-investment-wrap {
  overflow: hidden;
  margin-top: 10px;
  background-color: rgba(33, 176, 146, 0.4);
  border-radius: 10px;
  .progress-investment {
    max-width: 100%;
    border-radius: 10px 0px 0px 10px !important;
    height: 8px !important;
    background-color: #21b092 !important;
  }
}

.owner-profile-header {
  margin-top: 25px;
}
.profile-container {
  p {
    line-height: 27px;
    color: var(--mor-text, #383838);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
}

.property-map {
  height: 400px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

/*======= Map Styling ============*/
.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
#GMapsID div div a div img {
  display: none;
}

// remove the wrapping container
.gm-style-cc:last-child {
  display: none !important;
}
// remove the link only
a[title="Report errors in the road map or imagery to Google"] {
  display: none !important;
}

.left-aligned {
  padding-left: 0 !important;
  p {
    text-align: left;
  }
}

.side-by-side-element.column:last-of-type {
  padding: 0px 10px 0px 10px;
}

.widget a {
  font-weight: bold;
}

.bold {
  font-weight: bold !important;
}

.footer-table {
  background: #dee2e5 !important;
}

.capitalize {
  text-transform: capitalize;
}

.investment-details-cell {
  .green-button {
    border-radius: 0.25rem;
    background-color: $green-button !important;
    .view-listing {
      color: white;
      background-image: unset;
      background-color: $green-button !important;
      .fa-file-lines {
        margin-right: 10px;
      }
    }
  }
}

.purchase-order-button {
  .fa-file-lines {
    margin-right: 10px;
  }
}

.purchase-order-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .main-header-modal-header {
    display: flex;
    justify-content: center;
    color: var(--neutral-dark-slate-354052, #354052);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    z-index: 100;
    .action_buttons {
      margin-left: auto;
    }
    .separator {
      display: flex;
      .modal-label {
        color: var(--text-gray-7F8FA4, #7f8fa4);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px; /* 100% */
      }
      .data-wrap {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        .content {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px; /* 192.857% */
        }
      }
      .left-side-modal {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        align-items: baseline;
      }

      .right-side-modal {
        align-items: baseline;
        display: flex;
        flex-basis: 50%;
        flex-direction: column;
      }
    }
  }
}

.investment-listings-table {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 30px;
  .investment-listing-header {
    display: flex;
    justify-content: start;
    .raw-cell {
      &.grade {
        padding-right: 9%;
      }
      &.rate {
        padding-right: 10%;
      }
      &.term-left {
        padding-right: 7.8%;
      }
      &.property {
        padding-right: 5.5%;
      }
      &.term-left {
      }
      &.location {
        padding-right: 7.8%;
      }
    }
    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      color: black;
    }
  }
  .basic-row {
    background-color: $neutral;
    display: flex;
    min-height: 68px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    &.investment-listing-header {
      font-weight: bold;
      background-color: transparent;
      @media only screen and (max-width: 628px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .raw-cell {
      min-height: 68px;
      display: flex;
      align-items: center;
      flex: 2;
      padding: 8px;
      box-sizing: border-box;
      text-align: left;
      &.location {
        text-align: left;
        flex-direction: column;
        justify-content: center;
        align-items: initial;
        color: black;
        .neighbourhood {
          margin-top: 5px;
        }
      }
      &.investment-progress {
        .progress-wrapper {
          flex-direction: column;
          justify-content: center;
          align-items: baseline;
          min-width: 249px;
        }
      }
      &.rate {
        color: black;
        text-align: left;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        .ltv {
          margin-top: 5px;
        }
      }
      &.friendly-id {
        text-align: left;
        flex-direction: column;
        justify-content: center;
        align-items: initial;
        &.friendly-id {
          margin-top: 5px;
          font-size: 12px !important;
        }
        span {
          color: black;
          &.property-use {
            font-size: 16px;
            margin-bottom: 5px;
          }
        }
      }
    }
    &.modal-content {
      background-color: transparent;
    }
  }
}

.investment-listing-header {
  margin-top: 30px;
  background-color: white;
  display: flex;
  min-height: 42px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  justify-content: start;
  padding-left: 10px;
  padding-right: 10px;
  td {
    color: #000 !important;
    text-align: center !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    border-bottom: none !important;
  }
}

span {
  display: inline-flex;
  align-items: center;
  &.grade {
    flex-basis: 14.6%;
  }
  &.percent {
    justify-content: flex-end;
    flex-basis: 10%;
  }
  &.rate {
    justify-content: flex-end;
    padding-right: 5%;
  }
  &.province {
    justify-content: flex-end;
    padding-right: 8%;
    @media (max-width: 1176px) {
      justify-content: unset !important;
    }
  }
  &.modal-trigger {
    position: relative;
    justify-content: flex-end;
    span {
      flex-basis: initial !important;
    }
  }
  @media (max-width: 635px) {
    flex-basis: 17%;
  }
}

.letter {
  z-index: 100;
  color: white;
}
.inner-grade-wrap {
  background-color: #21b092;
  width: 28px;
  height: 28px;
  transform: translate(68%, 0);
  z-index: 10;
  border-radius: 50%;
}

.table-builder {
  @media only screen and (max-width: 628px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  .faux-row {
    &.wrap-collapsible {
      .faux-cell {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        border-bottom: none;
        &.investment-listing-grey {
          background-color: #f2f2f2 !important;
        }
        &.investment-listing-purple {
          background-color: $info;
          color: white;
          &:first-of-type {
            border-bottom-left-radius: 0 !important;
          }
          &:last-of-type {
            border-bottom-right-radius: 0 !important;
          }
          .grade {
            &.grade-i {
              .background-circle-fa {
                span {
                  color: $primary;
                }
              }
            }
          }
        }
        @media only screen and (device-width: 456px) {
          max-height: initial !important;
        }
        &:first-of-type {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        &:last-of-type {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      border-radius: 10px;
      .modal-trigger {
        position: relative;
        display: flex;
        justify-content: center;
        .fa-minus {
          position: absolute;
          font-size: 17px;
          right: 6px;
          top: 6px;
        }
        .fa-plus {
          position: absolute;
          font-size: 17px;
          right: 6px;
          top: 6px;
        }
        .fa-circle {
          margin-left: auto;
          font-size: 28px;
        }
      }
    }
  }
}

// Overrides for the accordions
.accordion-items {
  &.content {
    .vertical-bar {
      height: 100%;
    }
    .credit-span {
      padding-top: 0;
      font-size: 16px;
    }
  }
}
.owner-profile-wrap {
  padding-left: 20px;
  padding-right: 20px;
}
// Test the Accordion
.content {
  max-height: 0;
  transition: all 0.5s ease-in-out;
  font-size: 18pt;
  overflow: auto;
  box-sizing: border-box;
  &.open {
    max-height: 1300px;
  }
  .content-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.modal-content {
  .faux-cell {
    &.raw {
      border-bottom: none;
      padding-bottom: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      &.top-padding-row {
        padding-top: 0 !important;
      }
    }
  }
}

.hide-on-tablet {
  @media only screen and (max-width: 815px) {
    display: none !important;
  }
}

.hide-on-mobile {
  @media only screen and (max-width: 628px) {
    display: none !important;
  }
}

.white-row {
  &.faux-cell {
    background-color: white !important;
  }
  .faux-cell {
    &.text {
      background-color: white !important;
    }
    &.raw {
      background-color: white !important;
    }
  }
}

.pending-action-queue-requests {
  .request-details {
    p {
      margin-bottom: 0;
    }
  }
  .faux-cell {
    &.action_buttons {
      background-color: white !important;
    }
  }
}

.inner-wrap-details {
  align-items: initial;
  flex-direction: column;
}

.contact-label {
  .inner-wrap {
    display: flex !important;
    .svg-inline--fa {
      margin-right: 8px;
    }
  }
  span {
    color: black !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
  }
}

.no-bottom-border {
  border-bottom: none !important;
}
gate .overview-table .faux-table tbody .faux-row .faux-cell .no-bold {
  font-weight: 400 !important;
}

.contact-us-row {
  color: black !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.875rem;
  @media only screen and (max-width: 412px) {
    white-space: break-spaces;
  }
}

.investment-table {
  &.transactions-modal {
    .table-header {
      .faux-cell {
        padding-bottom: 10px !important;
        padding-left: 10px !important;
      }
    }
  }
  .search-box-container {
    display: none !important;
  }
}

#close-modal {
  max-width: 13%;
  margin-left: auto;
  @media only screen and (max-width: 414px) {
    max-width: none;
  }
}

.black-important {
  color: black !important;
  span {
    color: black !important;
  }
}

.red-important {
  color: $danger !important;
  span {
    color: $danger !important;
  }
}

.investment-ownership-details-container {
  .vertical-bar {
    height: 100%;
  }
  .credit-span {
    padding-top: 0 !important;
  }
  .owner-profile-wrap {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.investment-ownership-details-header {
  margin-top: 30px;
}

.action_buttons {
  &.investment-ownership-view-more-listings {
    background-color: $primary;
  }
}

.green-button {
  border-radius: 0.25rem;
  .view-listing {
    color: white;
    background-image: unset;
    background-color: $green-button !important;
    .fa-file-lines {
      margin-right: 10px;
    }
  }
}

.button-green {
  border-radius: 0.25rem;
  color: white !important;
  background-image: unset;
  background-color: $green !important;
  &:hover {
    color: white !important;
    background-image: unset;
    background-color: $green !important;
  }
}

.button-red {
  border-radius: 0.25rem;
  color: white !important;
  background-image: unset;
  background-color: $bright_red !important;
  &:hover {
    color: white !important;
    background-image: unset;
    background-color: $bright_red !important;
  }
}

.main-content {
  &.login {
    min-height: calc(100vh - 14.6rem) !important;
    .greeting {
      margin-top: 60px;
    }
  }
}

.footer {
  &.login-page {
    position: fixed;
    bottom: 0;
    max-width: 100%;
    background-color: $footer-grey;
    padding-left: 15%;
    padding-right: 15%;
    @media (max-width: 375px) {
      padding-left: 7%;
      padding-right: 9%;
    }
    .footer__phone-link {
      padding-top: 7px;
    }
    .forgot-password {
      padding-top: 7px;
    }
  }
}

.dashboard-content {
  .footer {
    max-width: 100%;
    background-color: $footer-grey;
    padding-left: 15%;
    padding-right: 15%;
    .footer__phone-link {
      padding-top: 7px;
    }
    .forgot-password {
      padding-top: 7px;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

#map {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.investment-ownership-table-row {
  .morcado-id {
    margin-left: 10px;
  }
}

.purchase-order-account-details {
  display: flex;
  flex-direction: column;
  align-items: initial;
  color: black;
  font-weight: 400;
  margin-bottom: 5px;
  .capitalize {
    flex-basis: initial;
    .user-nickname {
      padding-left: 5px;
      flex-basis: initial;
    }
  }
  .morcado-id {
    margin-top: 2px;
    margin-left: 0;
    color: var(--dark-gray-979797, #979797);
  }
}

.dashboard-account-nickname {
  display: flex !important;
  span {
    flex-basis: initial !important;
  }
  .user-nickname {
    margin-top: 0 !important;
    margin-left: 3px;
  }
}

.transaction-pending {
  color: #8e9cad !important;
  td {
    color: #8e9cad !important;
  }
}

.data-dog-wrap {
  color: black !important;
}

.span-no-width {
  span {
    flex-basis: initial !important;
  }
}

.mobile-header {
  @media only screen and (max-width: 414px) {
    font-size: 26px;
  }
}

.mobile-header-investment-account {
  @media only screen and (max-width: 414px) {
    font-size: 26px;
  }
}

.table-no-display-block {
  table {
    @media only screen and (max-width: 628px) {
      display: table !important;
    }
  }
}

.flex-column-mobile {
  @media only screen and (max-width: 414px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  span {
    @media only screen and (max-width: 414px) {
      margin-right: auto;
    }
  }
}

.modal-close {
  @media only screen and (max-width: 414px) {
    display: none;
  }
}

.spinner {
  @media only screen and (max-width: 414px) {
    left: 32% !important;
  }
}

// UPDATE THE INVESTMENT LISTINGS TO MATCH THE INVESTOR SITE !
.wrap-collabsible {
  margin-bottom: 3px;
  color: black;
  background: $neutral;
  border-radius: 10px;
  min-height: 42px;
  &.rounded {
    background-color: $primary-color;
    color: white;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    .background-circle-fa {
      color: $grade-highlight;
    }

    .accordion-icon {
      border: 2px solid white;
      color: white;
      svg {
        transform: rotate(45deg);
      }
    }
  }

  .property {
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    text-align: start;

    span {
      @media (max-width: $sm) {
        text-align: start;
      }
    }
  }
  .id {
    font-size: 12px;
    @media (max-width: $sm) {
      display: none;
    }
  }
}
.row {
  display: flex;
  .bold {
    color: black;
  }
  &.progress {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .progress-bar {
    background-color: #d9d9d9;
    width: 100%;
    border: solid 2px #c4c4c4;
    border-radius: 20px;
    height: 10px;
    .progress {
      background-image: $gradient;
      height: 100%;
    }
  }
}
.investor-listing-map-container {
  .map-header {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: $primary-color;
    min-height: 42px;
  }
}
.content-container {
  color: $primary-color;
  font-weight: 500;
  display: flex;
  padding: 0 15px;
  flex-direction: column;

  .left {
    flex-basis: 50%;

    .purple-line {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .line {
        background-color: $primary-color;
        height: 100%;
        width: 4px;
      }
    }
  }

  .right {
    flex-basis: 50%;
    color: black;
    font-weight: 300;
  }
}

.small-header {
  font-size: 12px;
}

.double-width-item {
  flex: 2 !important;
}

.header-progress-wrap {
  flex-basis: unset !important;
  width: 100%;

  .header-progress {
    flex-basis: unset !important;
    background-color: $primary-color;
    width: 100%;
    border: solid 2px #c4c4c4;
    border-radius: 18px;
    position: relative;
    // padding: 1px;
    height: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    .progress {
      flex-basis: unset !important;
      background-color: $primary-color;
      position: absolute;
      line-height: inherit;
      height: 100%;
      border-radius: 18px;
    }
  }
}

.accordion-icon {
  position: relative;
  margin-left: auto;
  .background-circle {
    color: black;
    position: absolute;
    font-size: 28px;
    right: 12px;
  }
  .fa-plus {
    color: black;
    position: absolute;
    right: 19px;
  }
  .fa-minus {
    right: 19px;
    position: absolute;
  }
}

.full-height {
  min-height: initial !important;
  height: initial !important;
}

.modal-content {
  z-index: 1000;
  .raw-cell {
    &.has-padding {
      max-height: 0;
    }
    &.top-padding-row {
      max-height: unset !important;
    }
  }
}

// Override the default colors !
.remove-bottom-header-padding {
  margin-bottom: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: #6f4f9e !important;
  color: white !important;
  .investment-listing-purple {
    span {
      color: white !important;
    }
  }
  .property-use {
    color: white !important;
  }
  .friendly_id {
    color: white !important;
  }
  .bold {
    &.small-header {
      color: white !important;
    }
  }
  .fa-circle {
    color: #937bb6 !important;
    &.background-circle {
      color: white !important;
    }
  }
  .progress {
    background-color: #21b092 !important;
  }
}

.list-header {
  display: flex;
  color: black;
  margin-top: 40px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  div {
    // flex-grow: 1;
    &.grade {
      span {
        padding-left: 18px;
      }
    }
    &.property {
      padding-left: 18px;
    }
    &.percent {
      display: flex;
    }

    &.location {
      display: flex;
      align-content: center;
      span {
        @media (max-width: 794px) {
          padding-right: 0 !important;
        }
      }
    }
    &.rate {
      display: flex;
    }
  }
}

// Animate the open and close !
.white-font {
  color: white !important;
}
.rotate {
  transition: transform 0.5s ease; /* Add a smooth transition effect */
  &.rotated {
    transform: rotate(45deg);
  }
}

.showSpaces {
  white-space: pre;
}

.investment-listing-spacer {
  height: 5px;
}

.bold {
  font-weight: bold !important;
}

.term_left {
  color: black !important;
}

.side-by-side-element {
  display: inline-block;
  flex-basis: 50%;
}

.flex-column {
  display: flex;
  padding-bottom: 0 !important;
}

.no-left-padding-date-component {
  input {
    padding-left: 28px !important;
  }
  .date-icon-wrap {
    position: relative;
  }
  .date-icon-wrap .fa-calendar {
    position: absolute;
    top: 7px;
    left: 9px;
  }
  .date-icon-wrap .flatpickr-input {
    padding-left: 27px;
    padding-top: 10px;
  }
  .column {
    padding-left: 0;
  }
}

.small-top-margin {
  margin-top: 20px;
}

.source_of_funds {
  .column {
    padding-left: 0 !important;
  }
}

.funding-money {
  .column {
    padding-left: 0;
  }
}

.inline-loading {
  height: 40px;
  margin-top: -30px;
  position: relative;
  top: 14px;
}

.margin-left-auto {
  margin-left: auto;
}

.grey-text {
  color: #8e9cad;
}

.red-text {
  color: $red !important;
}

.purchase-investment-wrap {
  display: flex;
  button {
    margin-left: auto;
  }
}

.not-allowed-to-withdraw {
  .no-padding-left {
    padding-left: 0 !important;
  }
  h1 {
    font-size: 14px;
  }
  p {
    font-size: 14px;
    text-align: initial;
  }
}

.flex-wrap {
  display: flex;
  p {
    margin-bottom: 0 !important;
  }
  .left {
    flex-basis: 50%;
  }
  .right {
    flex-basis: 50%;
    &.location-purchase {
      flex-basis: 66%;
    }
  }
}

.no-bottom-padding {
  padding-bottom: 0 !important;
}

.column {
  &.side-by-side-element {
    &.no-padding-bottom {
      padding-bottom: 0 !important;
    }
  }
}

.investment-account-select {
  option {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
  }
}

.note-top-padding {
  padding-top: 25px;
}

.purchase-grade-small {
  .background-circle-fa {
    font-size: 15px;
    span {
      position: absolute;
      color: white;
      font-size: 9px;
      padding-top: 3px;
      padding-left: 1px;
    }
  }
}

.no-padding-bottom {
  padding-bottom: 2px !important;
}

.max-w-160 {
  max-width: 160px;
}
